.replaced-orders {
  padding: 4px 12px 4px 6px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 4px;
  border: none;
  justify-content: space-between;
  background-color: #f6f3ef;
  
  &:focus-visible{
    outline: 2px solid;
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
}

.replace-expand-more {
  color: #4b3d2b;
}

.replacement-order-mui-popover {
  .MuiPaper-root {
    border-radius: 16px;
    transition: none;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #d1d1d6;
    margin-top: -12px;
    margin-left: -14px;
  }
}

.replacement-popup {
  padding: 1rem 1.25rem 0 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  .replacement-popup-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.25rem;
    font-weight: 500;
    font-size: 0.875rem;

    .replace-restart-alt {
      color: #a2845e;
    }
  }

  .replacement-popup-list {
    ul {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      list-style: none;
      margin: 0;
      padding: 0 0 50px 0;

      max-height: 21.938rem;
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    li {
      display: grid;
      grid-template-columns: subgrid;
      grid-column: 1/-1;
      gap: 1.25rem;

      &:not(:last-child) {
        border-bottom: 1px solid #e5e5e9;
      }

      .order-id,
      .replacement-date,
      .replacement-value {
        font-weight: 400;
        font-size: 0.875rem;
      }

      .replacement-date,
      .replacement-value {
        color: #636366;
      }

      .replacement-date {
        text-align: right;
      }

      a {
        padding: 0.25rem 0.375rem 0.25rem 0.625rem;

        > * {
          align-self: center;
          color: #232324;
          text-decoration: none;
        }
      }
    }

    li:hover,
    li:focus-within {
      background-color: #f2f2f7;
      border-bottom-color: transparent;
      border-radius: 0.5rem;

      a {
        > * {
          color: #232324 !important;
        }

        text-decoration: none;
      }
    }

    li:focus-within {
      outline: auto;
    }

    li:has(+ li:hover),
    li:has(+ li:focus-within) {
      border-bottom-color: transparent;
    }

    a {
      display: grid;
      grid-template-columns: subgrid;
      grid-column: 1/-1;
    }

    .text-align-right {
      text-align: end;
    }

    .replacement-popup-expand-more {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #636366;

      span {
        transform: rotate(270deg);
      }
    }

    &.foggy::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50px;
      background: linear-gradient(
        to top,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0)
      );
      pointer-events: none;
    }
  }

  p {
    margin: 0;
  }
}
