.order-status {
    padding: 0.125rem 0.625rem !important;
    border-radius: 31.25rem;
    margin-right: 0.438rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #636366 !important;
    width: fit-content;
    white-space: nowrap;

    .placed-status {
        color: #0f3819 !important;
    }

    .in-process-status,
    .processed-status,
    .in-storage-status,
    .shipped-status,
    .unusual-status {
        color: #ffffff !important;
    }

    .hold-status {
        color: #472a00 !important;
    }

    .cancelled-status {
        color: #47110d !important;
    }
}