@import "./variable.scss";

.new-order-detail {
  .text-primary {
    color: $gray-50 !important;
    /* Tailwind's text-gray-50 */
  }

  .nagative-text-red {
    color: $red-300 !important;
  }

  .text-secondary {
    color: $gray-400 !important;
    /* Tailwind's text-gray-400 */
  }

  .text-tertiary {
    color: $gray-600;
    /* Tailwind's text-gray-600 */
  }

  .text-invert {
    color: $gray-1100;
    /* Custom dark gray, assuming text-gray-1100 */
  }

  .text-brand {
    color: $blue-500;
  }

  .bg-surface-primary {
    background-color: $gray-1100;
    /* Custom dark gray, assuming bg-gray-1100 */
  }

  .bg-surface-secondary {
    background-color: $gray-900;
    /* Tailwind's bg-gray-900 */
  }

  .bg-surface-tertiary {
    background-color: $gray-800;
    /* Tailwind's bg-gray-800 */
  }

  .bg-surface-alt {
    background-color: $gray-1000;
    /* Custom dark gray, assuming bg-gray-1000 */
  }

  .bg-surface-invert {
    background-color: $gray-100;
    /* Tailwind's bg-gray-100 */
  }

  .fill-surface-invert {
    fill: $gray-100;
    /* Tailwind's fill-gray-100 */
  }

  .bg-surface-brand {
    background-color: $blue-500;
    /* Tailwind's bg-blue-500 */
  }

  .fill-surface-secondary {
    fill: $gray-900;
    /* Tailwind's fill-gray-900 */
  }

  .border-primary {
    border-color: $gray-800 !important;
    /* Tailwind's border-gray-800 */
  }

  .border-secondary {
    border-color: $gray-700;
    /* Tailwind's border-gray-700 */
  }

  .bg-green-primary {
    background-color: $green-300;
    /* Tailwind's bg-green-300 */
  }

  .text-green-primary {
    color: $green-300;
    /* Tailwind's bg-green-300 */
  }

  .bg-green-secondary {
    background-color: $green-700;
    /* Tailwind's bg-green-700 */
  }

  .bg-indigo-primary {
    background-color: $indigo-500;
    /* Tailwind's bg-indigo-500 */
  }

  .bg-purple-primary {
    background-color: $purple-200;
    /* Tailwind's bg-purple-200*/
  }

  .bg-purple-secondary {
    background-color: $purple-500;
    /* Tailwind's bg-purple-500*/
  }

  .bg-purple-tertiary {
    background-color: $purple-800;
    /* Tailwind's bg-purple-800*/
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Debug */
  .debug {
    border-width: 0.125rem;
    border-color: $blue-500;
    /* border-blue-500 */
  }

  /* Typography */
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  .subtitle1,
  .subtitle2,
  .subtitle3,
  .overline1,
  .overline2 {
    font-weight: 500;
    /* font-medium */
  }

  h1,
  .h1 {
    font-size: 1.5rem;
    margin: 0;
    /* text-2xl */
    line-height: 1.32;
    letter-spacing: -0.02em;
  }

  h2,
  .h2 {
    font-size: 1.25rem;
    /* text-xl */
    line-height: 1.28;
    letter-spacing: -0.01em;
  }

  h3,
  .h3 {
    font-size: 1.125rem;
    /* text-lg */
    line-height: 1.26;
  }

  .p1,
  .subtitle1 {
    line-height: 1.26;
  }

  .p2,
  .subtitle2 {
    font-size: 0.875rem;
    /* text-sm */
    line-height: 1.22;
  }

  .p2 {
    margin: 0;
  }

  .p3,
  .subtitle3 {
    font-size: 0.75rem;
    /* text-xs */
    line-height: 1.18;
  }

  .overline1,
  .overline2 {
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .overline1 {
    font-size: 0.75rem;
    /* text-xs */
  }

  .overline2,
  .caption {
    font-size: 0.625rem;
    /* text-[10px] */
  }

  .caption {
    line-height: 1.16;
  }

  .text-align-left {
    text-align: left;
  }

  .transform-lowercase {
    text-transform: lowercase;
  }

  /* Buttons */
  .btn {
    font-weight: 500;
    /* font-medium */
    font-size: 0.875rem;
    /* text-sm */
    background-color: $gray-100;
    /* bg-surface-invert */
    color: $gray-1100 !important;
    /* text-invert */
    padding: 0.625rem 0.75rem;
    /* py-2.5 px-3 */
    border-radius: 0.75rem;
    /* rounded-xl */
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.18;

    span {
      padding: 0 0.25rem;
      /* px-1 */
    }

    &:disabled {
      opacity: 0.8;
    }
  }

  .od-btn-small {
    padding: 0.375rem 0.5rem;
    /* py-1.5 px-2 */
    border-radius: 0.375rem;
    /* rounded-lg */
  }

  .od-btn-mid {
    padding: 0.75rem 2.8125rem;
    border-radius: 0.75rem;
    /* rounded-lg */
  }

  .od-btn-accent {
    background-color: $blue-500;
    /* bg-surface-brand */
  }

  .od-btn-secondary {
    background-color: $gray-900;
    /* bg-surface-secondary */
    color: $gray-50 !important;
    /* text-primary */
  }

  .od-btn-outline {
    background-color: $black-bg-color;
    /* bg-surface-primary */
    color: $gray-50 !important;
    /* text-primary */
    border-color: $gray-700;
    /* border-secondary */
  }

  .od-btn-danger {
    background-color: $red-bg-color;
    /* bg-surface-primary */
    color: $red-200 !important;
    /* text-primary */
    border-color: $red-bg-color;
    /* border-secondary */
  }

  /* Interactive */
  .interactive {
    &:not(:disabled) {
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      outline-offset: 0rem;
      transition: transform 75ms cubic-bezier(0.4, 0, 0.2, 1);

      &:focus-visible {
        outline: 0.125rem solid $blue-500;
      }

      &:focus-visible,
      &:hover {
        filter: brightness(0.95);
      }

      &:active {
        transform: scale(0.975);
        filter: brightness(0.9);
      }
    }
  }

  .divider-opacity {
    opacity: 1;
  }

  /* Cards */
  .card {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    border-width: 0.0625rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .my-custom-gap {
    margin-top: 1rem;
    /* Adjust as needed */
  }

  .d-flex.gap-7 {
    margin-top: 1.75rem;
    /* Adjust as needed */
  }

  .d-flex.gap-2 {
    margin-top: 0.5rem;
    /* Adjust as needed */
  }

  .reverse-row {
    flex-direction: row-reverse;
  }

  .row {
    margin-right: -0.3125rem;
    /* Negative margin to counteract the gutter */
    margin-left: -0.3125rem;
    /* Negative margin to counteract the gutter */
  }

  .row>[class^="col-"] {
    padding-right: 0.3125rem;
    /* Half of the gap-x value */
    padding-left: 0.3125rem;
    /* Half of the gap-x value */
  }

  .shrink-0 {
    flex-shrink: 0;
  }

  .sr-only {
    position: absolute;
    width: 0.0625rem;
    height: 0.0625rem;
    padding: 0;
    margin: -0.0625rem;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  .py-0_5 {
    padding-top: 0.125rem;
    /* 2px */
    padding-bottom: 0.125rem;
    /* 2px */
  }

  .custom-padding-x {
    padding-left: 0.5rem;
    /* Equivalent to Tailwind's `px-2` */
    padding-right: 0.5rem;
  }

  .custom-padding-right {
    padding-right: 0.25rem;
    /* Equivalent to Tailwind's `pr-1` */
  }

  .custom-rounded-lg {
    border-radius: 0.5rem;
    /* Equivalent to Tailwind's `rounded-lg` */
  }

  .custom-hover-bg {
    transition: background-color 0.2s;
  }

  .order-details-wrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: 0;
    padding-top: 3.813rem;
    padding-inline: 1.5rem !important;
    padding-bottom: 50vh;
    width: 100%;
  }

  .archived-label {
    margin: 0;
  }

  .archived-wrapper {
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: subgrid;
    gap: 1rem;
    position: relative;
    height: 80px;
    overflow: hidden;
    user-select: none;

    .archived-paper {
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0;
      z-index: -1;

      &::after {
        content: "";
        position: absolute;
        z-index: 2;
        inset: 0;
        height: 100%;
        width: 100%;
        background-size: 100% 80px;
        background-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0),
            #fff 100%);
        background-repeat: no-repeat;
      }
    }

    .archived-tape {
      grid-column: 3 / 4;
      width: 100%;
      height: 60px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding: 10px;
      mix-blend-mode: multiply;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      background: linear-gradient(-10deg, $brown-500 0%, $brown-700 35%);
      mask-size: 14% 100%;
      mask-position: center bottom;
      mask-repeat: repeat-x;
      mask-image: radial-gradient(circle at center 102%,
          transparent 5px,
          white 0);
      -webkit-mask-size: 14% 100%;
      -webkit-mask-position: center bottom;
      -webkit-mask-repeat: repeat-x;
    }
  }

  .order-detail-main {
    display: grid;
    grid-column: 1/-1;
    grid-template-columns: subgrid;
    column-gap: 1.25rem;
    row-gap: 3.75rem;
    padding-top: 80px;

    &.order-detail-main-archived {
      padding-top: 0;
    }

    div {
      scroll-padding-top: 60px;
      scroll-behavior: auto;
    }

    .order-detail-navigation {
      max-width: 12.5rem;
      min-width: fit-content;
    }

    .order-flags {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.625rem;

      .order-flag {
        padding: 0.25rem 0.75rem 0.25rem 0.375rem;
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 0.25rem;
        border: 1px solid #e5e5e9;
        justify-content: center;
        width: fit-content;
      }

      a {
        color: #232324 !important;
        text-decoration: none;
      }

      a:hover {
        color: #232324 !important;
        text-decoration: none;
      }

      .release-lock {
        color: #ff9500;
      }

      .not-for-release {
        background-color: #fff4e6;
        border: none;
      }

      .replacement-title-btn {

        &:hover,
        &:focus-visible {
          background-color: #f2f2f7;
        }
      }
    }

    .replace-restart-alt {
      color: #a2845e;
    }

    .order-manage {
      &-details {
        display: flex;
        flex-direction: column;

        .order-manage-details-line {
          border-bottom: 1px solid $light-gray-color !important;
          padding-block: 10px;
          padding-inline-end: 12px;
          background-color: #fff;
          transition: all 0.6s ease-out;

          &.order-manage-line-highlighted {
            background-color: #f2f2f7;
            transition: all 1s cubic-bezier(0.33, 0.41, 0, 1.27);
          }

          .order-manage-toggle-group {
            gap: 10px;
          }
        }
      }

      .emails-redirect {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: $gray-400;
      }

      .common-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
      }

      &-hold {
        .common-text {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
        }
      }
    }

    .order-items {
      margin-inline-end: -1.5rem;
      grid-column: 3 / -1;
    }

    .order-invoices,
    .credit-memos {
      grid-column: 3 /10;
    }

    .order-shipments {
      grid-column: 3 / -1;
    }

    .order-manage,
    .order-document,
    .order-common-log,
    .overview-table {
      grid-column: 3/10;
    }

    .order-totals-group {
      display: flex;
      flex-direction: column;
      gap: 20px;
      grid-column: 10 / -1;
      grid-row: 1 / span 5;
      align-items: flex-start;
    }
  }

  .order-detail-nav {
    position: sticky;
    top: 5rem;
    grid-column: 1/ 3;
    height: fit-content;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    a {
      text-decoration: none;
    }

    .order-detail-nav-li {
      padding: 0.125rem 0.625rem;
      border-radius: 0.375rem;
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      line-height: 1.22;
      color: $gray-400 !important;
      background-color: #fff;
      border: none;
      width: 100%;

      &:hover {
        color: $gray-50 !important;
        background-color: $gray-900;
      }

      &:focus-visible {
        color: $gray-50 !important;
        background-color: $gray-900;
      }

      .font-medium {
        font-weight: 500;
      }

      /* Handle data attribute styles */
      &[data-active="true"] {
        font-weight: 500;
        /* Tailwind's font-medium */
        background-color: $gray-900;
        color: $gray-50 !important;
      }
    }
  }

  .order-detail-column {
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
    grid-column-start: 3;
    grid-column-end: 10;

    p {
      margin: 0;
    }
  }

  .whitespace-nowrap {
    white-space: nowrap;
  }

  .flex-column-gap-2 {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    /* 0.5rem is equivalent to Tailwind's gap-2 */
  }

  .flex-gap-3 {
    display: flex;
    gap: 0.75rem;
    /* 0.75rem is equivalent to Tailwind's gap-3 */
  }

  .flex-gap-2-items-center {
    display: flex;
    gap: 0.5rem;
    /* Assuming 1rem in Tailwind is equal to 16px, so 0.5rem is 8px */
    align-items: center;
  }

  .flex-gap-2 {
    display: flex;
    gap: 0.5rem;
    /* Assuming 1rem in Tailwind is equal to 16px, so 0.5rem is 8px */
  }

  .flex-gap-4 {
    display: flex;
    gap: 1rem;
    /* Assuming 1rem in Tailwind is equal to 16px, so 1rem is 16px */
  }

  .flex-gap-1 {
    display: flex;
    gap: 0.25rem;
  }

  .flex-col {
    flex-direction: column;
  }

  // Define a placeholder selector for height and width
  %h-w-4 {
    height: 1rem; // Assuming 1rem in Tailwind is equal to 16px
    width: 1rem; // Assuming 1rem in Tailwind is equal to 16px
  }

  // Usage example for h-4 w-4
  .custom-element {
    @extend %h-w-4;
  }

  .pd-2 {
    padding: 0.5rem;
  }

  .btn-element {
    // Background color
    background-color: $gray-900; // Assuming bg-surface-secondary in Tailwind is #1f2937

    // Padding
    padding: 0.25rem 1rem; // Equivalent to px-2 py-1

    // Border radius
    border-radius: 1rem; // Equivalent to rounded-2xl

    // Width
    max-width: 16rem; // Equivalent to max-w-64

    // Positioning
    position: relative;
    margin-left: 0.5rem !important;
    display: flex;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.75rem;

    // Hover and focus styles
    &:hover,
    &:focus-visible {
      color: $gray-50 !important; // Assuming text-primary in Tailwind is blue-1200
    }
  }

  .truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .customer-name-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    max-width: 45ch;

    &.no-delivery-note {
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }

    .add-delivery-note {
      gap: 4px;
      padding: 6px 8px;
      border-radius: 10px;

      &:focus-visible {
        outline: auto;
      }

      span {
        padding: 0;
      }
    }

    .edit-delivery-note {
      display: flex;
      align-items: center;
      gap: 4px;

      &:link {
        color: inherit;
        text-decoration: none;
      }

      &:focus-visible {
        outline: 2px solid;
        filter: brightness(0.9);
      }
    }
  }

  .customer-detail-group {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    max-width: fit-content;
    padding: 0.25rem 0.35rem;
    padding-right: 0.5rem;
    position: relative;
    border-radius: 8px;
    margin-inline-start: -0.35rem;
    background-color: white;
    border: none;
    text-align: left;
  }

  .customer-detail-group:hover,
  .customer-detail-group:focus-visible {
    background-color: $gray-900;
    color: #000;
  }

  .customer-detail-group:hover {
    outline-width: 0.25rem;
    /* Assuming outline-4 is 0.25rem */
  }

  .customer-detail-group.rounded-md {
    border-radius: 0.375rem;
    /* Assuming rounded-md is 0.375rem */
  }

  .customer-detail-group:hover.outline-4 {
    outline-color: $blue-1000;
    /* Assuming primary color is blue */
  }

  .customer-detail-group.inset-x-1 {
    left: -0.25rem;
    /* Assuming inset-x-1 is -0.25rem */
    right: -0.25rem;
    /* Assuming inset-x-1 is -0.25rem */
  }

  .customer-detail-group.interactive {
    cursor: pointer;
  }

  .order-customer-detail {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(4, min-content);
    column-gap: 1.25rem;
    border-bottom-width: 0.0625rem;

    &-section {
      display: grid;
      grid-row: 1 / span 4;
      grid-template-rows: subgrid;
      gap: 1rem;
      border-top-width: 0.0625rem;
      padding-top: 1rem;

      a:is(.customer-detail-group:not(.nav-link,
          .dropdown-item,
          .page-tips-footer a,
          .btn)) {
        color: black !important;
        width: fit-content;
        float: right;
        text-decoration: none;
        border-radius: 0.375rem;

        &:visited,
        &:link,
        &:hover,
        &:active {
          color: black !important;
        }

        &:focus-visible {
          outline: 0.125rem solid $blue-500;
        }
      }
    }

    p {
      margin: 0;
    }
  }

  .whitespace-pre-line {
    white-space: pre-line;
  }

  .whitespace-normal {
    white-space: normal;
  }

  .word-break {
    word-break: break-word;
  }

  .order-detail-placed-title {
    display: inline-flex;
    align-items: center;
    padding: 0.125rem 0.5rem;
    border-radius: 0.375rem;
    color: $gray-350;
    cursor: pointer;
    transition: color 0.3s, background-color 0.3s;
    border: none;
    background-color: $gray-1100;

    &:hover,
    &:focus-visible {
      color: $blue-1200;
      /* Adjust as needed */
      background-color: $gray-900;
    }
  }

  .order-detail-placed-title:hover,
  .order-detail-placed-title:focus-visible {
    color: #3b82f6;
    /* Adjust as needed */
  }

  // .order-detail-placed-title:hover,
  // .order-detail-placed-title:focus-visible {
  //   background-color: #e5e7eb; /* Adjust as needed */
  // }

  .p2 {
    font-size: 0.875rem;
    line-height: 1.22;
    margin: 0;
  }

  .order-total-container {
    width: 100%;
    border-radius: 1rem 0rem 0rem 0rem;
    gap: 1rem;
    flex-direction: column;
    display: flex;

    &-bar {
      height: 0.375rem;
    }

    &-chart {
      margin-top: 0.25rem;
    }

    &-label {
      font-family: Inter;
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 18px;
      color: $gray-400;
    }

    &-value {
      font-family: Inter;
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: -0.02em;
      text-align: left;
    }

    &-line {
      width: 18rem;
      height: 0.063rem;
      gap: 0px;
      opacity: 0px;
      background: var(--border-border-primary, $gray-800);
    }

    p {
      margin: 0;
      padding: 0;
    }

    ul {
      padding: 0;
      margin: 0;
    }

    .report-deposit-percent-gap {
      gap: 0.313rem;
    }

    .report-deposit-percent {
      line-height: 2.5;
    }
  }

  .partially-shippable {
    padding: 0.25rem;
  }

  .h-6 {
    height: 1.5rem;
  }

  .cursor-crosshair {
    cursor: crosshair;
  }

  .rounded-lg {
    border-radius: 0.5rem;
  }

  .h-3 {
    height: 0.75rem;
  }

  .w-3 {
    width: 0.75rem;
  }

  .transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .duration-100 {
    transition-duration: 100ms;
  }

  .rounded-full {
    border-radius: 624.9375rem;
  }

  .rounded-md {
    border-radius: 0.375rem;
  }

  .gap-0-5 {
    gap: 0.125rem;
  }

  .customer-delivery-note {
    border-radius: 1rem;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    max-width: 45ch;
    display: flex;
    margin-left: 6px;
    position: relative;
    background-color: $gray-900;
    border: none;
    line-height: 12px;
    transform-origin: top left;
    animation: delivery-note-intro 1s cubic-bezier(0.42, 0.1, 0, 1.2);

    &:hover,
    &:focus-visible {
      filter: brightness(0.9);
    }

    @keyframes delivery-note-intro {
      from {
        transform: scale(0.9) translateY(-5%);
        opacity: 0;
        filter: blur(2px) brightness(1);
      }
    }

    .chat-bubble-tail {
      fill: $gray-900;
      position: absolute;
      left: -0.4rem;
      top: 0;
    }

    .delivery-note-char {
      opacity: 0;
      animation: delivery-note-char-intro 0.6s ease-out forwards;

      @keyframes delivery-note-char-intro {
        to {
          opacity: 1;
        }
      }
    }
  }

  .order-detail-container-dropdown {
    width: 28.125rem;
    max-height: 37.5rem;
    border-radius: 1rem;
  }

  .order-detail-container-dropdown-order-closed {
    width: 30.125rem;
  }

  .dropdown {
    .dropdown-menu {
      .sub-quote-list {
        overflow-y: scroll;
        max-height: 25.5rem;
      }
    }
  }

  .order-close {
    font-size: 1rem;
    line-height: 1.625rem;
    font-weight: 500;
  }

  .flex-gap-1-5 {
    display: flex;
    gap: 0.375rem;
  }

  .order-detail-report-section {
    .order-detail-report-container {
      .report-div {
        position: relative;
        padding: 0.625rem 0.6375rem 0.3125rem 0.6255rem;
      }

      .border-bottom {
        border-bottom: 0.063rem solid #eeeeee;
      }

      .title {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.375rem;
      }

      .ml-2 {
        margin-left: 0.5rem;
      }

      .round-title {
        border-radius: 500px;
        background-color: $red-900;
        font-weight: 500;
        color: $red-200;
      }

      .text-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 10rem;
        display: block;
      }
    }
  }

  .rounded-xl {
    border-radius: 0.75rem;
  }

  .deposit-option {
    padding-left: 1.75rem;
    gap: 1rem;
    display: flex;

    &:focus-within {
      background-color: $gray-1100;
      outline: 0.0625rem solid;
    }
  }

  .deposit-input {
    width: 5rem;
    padding-right: 1rem;
    border-radius: 0.5rem;
    min-width: 0rem;
    background-color: transparent;
    outline: 0.125rem solid transparent;
    outline-offset: 0.125rem;
    text-align: right;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    &::placeholder {
      color: $gray-600;
    }
  }

  .right-0 {
    right: 0rem;
  }

  .pointer-events-none {
    pointer-events: none;
  }

  .deposit-value {
    padding-left: 0.75rem;
    border-radius: 0.5rem;
    min-width: 0rem;
    background-color: transparent;
    outline: 0.125rem solid transparent;
    outline-offset: 0.125rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .deposit-radio-label {
    display: flex;
    width: 100%;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 2rem;
    padding-right: 0.75rem;
    border-radius: 0.5rem;
    cursor: pointer;

    h3 {
      margin: 0;
    }
  }

  .deposit-radio-label input[type="radio"] {
    display: none;
  }

  .deposit-radio-label input[type="radio"]:checked {
    background-color: $gray-1100;
  }

  .text-right {
    text-align: right !important;
  }

  .max-w-20 {
    max-width: 5rem;
  }

  .min-w-20 {
    min-width: 5rem;
  }

  .custom-checkbox .hidden-element {
    position: absolute;
    visibility: hidden;
    margin-left: 0.75rem;
  }

  .custom-checkbox input[type="radio"]:checked .hidden-element {
    visibility: visible;
  }

  .fs-14 {
    font-size: 0.875rem;
    font-weight: 400;
  }

  .text-green-50 {
    color: $gray-50;
  }

  .rounded-full {
    border-radius: 50%;
  }

  .p-0-5 {
    padding: 0.125rem;
  }

  @keyframes outline-pulse {
    to {
      outline: solid 0.125rem $gray-50;
    }
  }

  .focus-visible-border {
    border: solid 0.0625rem $gray-800;
    outline: solid 0.0625rem $gray-800;
    animation: outline-pulse 1s ease-out alternate infinite;
  }

  .flex-gap-10 {
    display: flex;
    gap: 0.625rem;
  }

  .order-detail-placed-customer-title {
    display: flex;
    flex-direction: column;
    color: black;
    cursor: pointer;
    transition: color 0.3s, background-color 0.3s;
    border: none;
    background: white;
    border-radius: 12px;
    padding: 8px;
    margin: -8px;

    &:hover,
    &:focus-visible {
      background-color: $gray-1100;
    }
  }

  .order-type-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .customer-popup-border-radius {
    padding: 0;
    border-radius: 0.625rem !important;
  }

  .customer-overview-popup {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 16px 20px;

    .customer-popup-info {
      display: flex;
      justify-content: space-between;
    }

    .customer-popup-edit-btn {
      height: fit-content;
      padding: 6px 12px;
      border-radius: 0.625rem;
    }

    .customer-overview-bottom-details {
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
    }

    .h3 {
      font-size: 1.125rem;
      line-height: 1.26;
      margin: 0;
    }
  }

  .order-job-title {
    .add-job-name {
      display: inline-flex;
      gap: 0.25rem;
    }

    .edit-job-name {
      display: inline;
      color: $gray-50 !important;

      .edit-job-name-icon {
        display: inline;
        visibility: hidden;
        vertical-align: sub;
      }

      &:hover,
      &:focus-visible {
        .job-name {
          text-decoration: underline;
        }

        .edit-job-name-icon {
          visibility: visible;
        }
      }

      &:focus-visible {
        outline: 2px solid;
      }
    }

    a.edit-job-name:link {

      &:hover,
      &:focus-visible {
        text-decoration: none;
      }
    }
  }

  .extra-charge {
    position: relative;
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      color: #232324 !important;
    }

    .extra-charge-btn {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background-color: #f2f2f7;
      padding: 6px 8px;
      border-radius: 10px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #232324 !important;

      &:hover {
        text-decoration: none;
        color: #232324 !important;
      }
    }
  }

  .my-6 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.all-info-description {
  font-size: 14px;
}

.all-info-username {
  font-size: 12px;
}

.order-detail-last-column {
  height: fit-content !important;
}

.remove-button-style {
  all: unset;
  cursor: pointer;
}