@import "apps/orderdetail/styles/variable.scss";

@mixin orderDocumentsStyles {
    .table-body {
        tr {
            td {
                padding-top: 0.313rem;
            }
        }

        .bordered-row {
            border-bottom: 1px solid $light-gray-color !important;
        }

        .common-text-style {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            color: $gray-400;
        }

        &-name {
            &-user-image {
                display: flex;
                gap: 0.5rem;
                position: relative;
                padding-right: 0;

                .image {
                    width: 3.75rem;
                    height: 3.75rem;
                    background-color: $gray-900;
                    border-radius: 0.75rem;
                }

                .no_document {
                    position: absolute;
                    top: 35%;
                    left: 24px;
                    width: 0.833rem;
                    height: 1.042rem;
                }

                .show-image {
                    width: 3.75rem;
                    position: absolute;
                    height: 3.75rem;
                    top: 4%;
                    left: 0;
                    border-radius: 12px;
                }
            }

            &-avatars {
                font-size: 14px;
                display: flex;
                align-items: center;
                gap: 0.313rem;
            }

            &-file-type {
                text-transform: uppercase;
            }

            &-description {
                &-first {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 14.063rem !important;
                    font-size: 14px;
                    color: $gray-50;
                    display: inline-block;
                }
            }
        }
    }
}

.user-order-details {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    .order-document {
        .view-document {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding-left: 4.875rem;
            padding-top: 0.938rem;
        }

        &-child {
            gap: 10px;
            display: flex;
            flex-direction: column;

            &-data {
                display: flex;
                flex-direction: column;
                gap: 15px;
                @include orderDocumentsStyles;

                .table-head {
                    top: unset;

                    tr {
                        th {
                            padding-top: 0;
                        }
                    }
                }
            }
        }
    }
}

.set-customer-code-modal-container {
    .MuiDialog-container {
        .MuiDialog-paper {
            width: 100%;
            min-width: 115vh;

            .MuiDialogContent-root {

                .order-document-child-data {
                    display: flex;
                    flex-direction: column;
                    gap: 0.938rem;
                    @include orderDocumentsStyles;

                    .table-head {
                        top: unset;
                    }
                }
            }
        }
    }
}