$gray-0: #1c1c1e;
$gray-50: #232324;
$gray-100: #2c2c2e;
$gray-200: #3a3a3c;
$gray-300: #48484a;
$gray-350: #6b7280;
$gray-400: #636366;
$gray-500: #8e8e93;
$gray-600: #aeaeb2;
$gray-700: #d1d1d6;
$gray-800: #e5e5e9;
$gray-900: #f2f2f7;
$gray-1000: #f9f9fb;
$gray-1100: #ffffff;

$indigo-50: #090916;
$indigo-100: #19183c;
$indigo-200: #292863;
$indigo-300: #38378a;
$indigo-400: #4847b0;
$indigo-500: #5856d7;
$indigo-600: #7e7ce0;
$indigo-700: #a3a2e9;
$indigo-800: #c9c8f2;
$indigo-900: #eeeefb;

$purple-50: #120816;
$purple-100: #31173e;
$purple-200: #512666;
$purple-300: #70358e;
$purple-400: #9043b6;
$purple-500: #af52de;
$purple-600: #c179e5;
$purple-700: #d3a0ed;
$purple-800: #e5c7f4;
$purple-900: #f7eefc;

$pink-500: #ff375f;

$brown-500: #a2845e;
$brown-700: #ccbba6;

$orange-50: #1a0f00;
$orange-100: #472a00;
$orange-200: #754500;
$orange-300: #a35f00;
$orange-400: #d17a00;
$orange-500: #ff9500;
$orange-600: #ffad39;
$orange-700: #ffc573;
$orange-800: #ffddac;
$orange-900: #fff4e6;

$red-50: #1a0605;
$red-100: #47110d;
$red-200: #751b16;
$red-300: #a3261e;
$red-400: #d13027;
$red-500: #ff3b2f;
$red-600: #ff675e;
$red-700: #ff938d;
$red-800: #ffbfbb;
$red-900: #ffebea;

$cyan-500: #54bef0;

$mint-500: #04c7be;

$yellow-500: #ffcc02;

$green-50: #051409;
$green-100: #0f3819;
$green-200: #185c29;
$green-300: #227f39;
$green-400: #2ca349;
$green-500: #35c759;
$green-600: #63d47e;
$green-700: #90e0a4;
$green-800: #bdedc9;
$green-900: #ebf9ee;

$blue-50: #000c1a;
$blue-100: #012247;
$blue-200: #013975;
$blue-300: #014fa3;
$blue-400: #0265d1;
$blue-500: #027bff;
$blue-600: #3b99fe;
$blue-700: #74b6ff;
$blue-800: #add4ff;
$blue-900: #e6f2ff;
$blue-1000: #007bff;
$blue-1200: #3b82f6;

$black-bg-color: #1a202c;
$red-bg-color: #ffebea;
$light-gray-color: #e0e5ec;
$dark-gray-color: #363d47;
