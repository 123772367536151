@import "../../styles/variable.scss";


.overview {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  &-item {
    display: flex;
    padding: 0.5rem 0;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: flex-start;

    &-key {
      color: $gray-400;
      width: 25rem;
      display: inline-block;
      padding-left: 1.875rem;
      flex-grow: 0;
      max-width: 33.333333%;
      flex-basis: 33.333333%;
    }

    &-value {
      flex-grow: 0;
      max-width: 66.666667%;
      flex-basis: 66.666667%;
      font-weight: 400;
      font-size: 0.875rem;
      color: $gray-50;

      .project-details {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;

        .order-details-icon {
          visibility: hidden;
          cursor: pointer;
        }

        .value {
          color: $gray-50;
          margin-bottom: 0.313rem !important;
        }

        .custom-select {
          color: $gray-50 !important;
          background: none !important;
          padding: 0.313px !important;
          width: 12rem !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
        }

        &-name {
          display: flex;

          &-owner {
            display: flex;
            align-items: center;

            &-select {
              background-color: unset;
            }
          }
        }

        &-button {
          display: flex;
          gap: 0.75rem;
          margin-right: 1.563rem;


          .manage-btn {
            height: 2.5rem;
            margin: 0 !important;
            width: 5rem;

            a {
              color: $gray-50 !important;
            }

            a:hover,
            :visited {
              color: $gray-50 !important;
              font-size: 0.875rem;
              text-decoration: none;
            }
          }

          .detach-btn {
            height: 2.5rem;
            border-radius: 0.7rem;

            a {
              color: $red-200 !important;
            }

            a:hover,
            :visited {
              color: $red-200 !important;
              font-size: 0.875rem;
              text-decoration: none;
            }
          }

          .unassign-btn {
            background: none;
            border: none;
            color: $red-200
          }
        }

        .icon-hide,
        .button-hide {
          visibility: hidden;
        }

        @media (max-width: 768px) {

          .icon-hide,
          .button-hide {
            visibility: visible;
          }
        }
      }
    }
  }

  .quote {
    min-height: 3.408rem
  }

  .overview-item:last-child {
    border-bottom: none;
  }

  .project-row {

    .project-details-name-owner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

  }
}

.not-assigned {
  color: grey;
}

.overview-item:nth-child(odd) {
  background-color: $gray-1000;
}

.project:hover .icon-hide,
.project:hover .button-hide {
  visibility: visible;
}

.project:focus-within .icon-hide,
.project:focus-within .button-hide {
  visibility: visible;
}

.project:hover .custom-select,
.project:focus-within .custom-select {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBkPSJNNC42NDYgNi42NDZhLjUuNSAwIDAgMSAuNzA4IDBMOCA5LjI5M2wyLjY0Ni0yLjY0N2EuNS41IDAgMCAxIC43MDguNzA4bC0zIDNhLjUuNSAwIDAgMS0uNzA4IDBsLTMtM2EuNS41IDAgMCAxIDAtLjcwOHoiLz4KPC9zdmc+Cg==') no-repeat right center !important;
}

.reset-button {
  all: unset;
  display: inline-block;
  cursor: pointer;
}