.order-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, minmax(max-content, 1fr));
    border-bottom: 1px solid #E5E5E9 !important;
    padding-bottom: 0.625rem;
    justify-content: space-between;
    align-items: center;

    .section-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2px;

        .order-title {
            font-weight: 500;
            line-height: 1.2;
            color: #232324;
            font-size: 18px;
        }

        .order-count {
            font-weight: 500;
            line-height: 1.2;
            color: #636366;
            font-size: 18px;
        }
    }

    .upload-btn {
        padding: 0.375rem 0.5rem 0.375rem 0.5rem;
        border-radius: 10px;
        background-color: #f2f2f7;
    }

    p {
        margin: 0;
    }

    .title-no-full-grid {
        grid-column: 8;
        margin-left: 1.875rem;
    }

    .title-full-grid {
        grid-column: -1;
    }
}