.order-details-icon {
  --size: 24;
  --calculated-size: calc(var(--size) * 1px);
  font-family: "Material Icons Rounded" !important;
  font-size: var(--calculated-size);
  width: var(--calculated-size);
  height: var(--calculated-size);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;
  user-select: none;
}
