.switch-track {
  height: 28px;
  width: 48px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #e5e5e9;
  padding: 7px;
  transition: all 0.2s cubic-bezier(0, 1.2, 0, 1.05);
  border: none;
  cursor: default;

  &:disabled {
    opacity: 0.5;
    filter: brightness(0.85);
  }

  &:not(:disabled) {
    cursor: pointer;

    &:focus-visible {
      outline: solid;
      outline-offset: 3px;
    }

    &:hover,
    &:focus-visible {
      background-color: #cbcbd3;

      &.switch-on {
        background-color: #027bff;
      }
    }

    &:active {
      background-color: #aeaeb2;

      &.switch-on {
        background-color: #027bff;
      }
    }

    &:active {
      padding: 4px;
      transition: all 0.2s cubic-bezier(1, 0, 0, 1);
    }

    &.switch-on {
      &:active {
        padding: 2px;
        padding-left: 22px;
        transition: all 0.2s cubic-bezier(1, 0, 0, 1);
      }
    }
  }

  &.switch-on {
    padding: 4px;
    padding-left: 24px;
    background-color: #027bff;
  }

  .switch-thumb {
    background-color: #fff;
    height: 100%;
    aspect-ratio: 1;
    border-radius: 500px;
  }
  .switch-input,
  .switch-status {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
}
