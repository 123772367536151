@import "apps/orderdetail/styles/variable.scss";

.user-order-details {
    .order-manage-note-log {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

        .order-details-data {
            width: 100%;

            .all-info {
                display: flex;
                flex-direction: column;
                gap: 5px;
                padding-bottom: 20px;
                padding-top: 10px;

                &-description {
                    margin-bottom: 0 !important;
                }

                &-username {
                    display: flex;
                    align-items: center;
                    gap: 6px;

                    .time {
                        color: $gray-400;
                    }
                }
            }
        }
    }

    .view-document {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding-top: 0.938rem;
    }
}

.set-order-manage-note-log {
    .MuiDialog-container {
        .MuiDialog-paper {
            width: 100%;
            min-width: 115vh;

            .MuiDialogContent-root {
                .order-manage-note-log {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: column;

                    .order-details-data {
                        width: 100%;

                        .all-info {
                            display: flex;
                            flex-direction: column;
                            gap: 5px;
                            padding-bottom: 15px;
                            padding-top: 5px;

                            &-description {
                                p {
                                    margin-bottom: 0;
                                }
                            }

                            &-username {
                                display: flex;
                                align-items: center;
                                gap: 6px;

                                .time {
                                    color: $gray-400;
                                }
                            }
                        }
                    }
                }

                .view-document {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    padding-top: 0.938rem;
                }
            }
        }
    }
}